import React from "react";
import Layout from "../components/global/Layout";

import Loadable from '@loadable/component'
const Above = Loadable(() => import("../components/global/GenericBanner"))

const NoContent = () => {
  return (
    <Layout>
      <Above whiteTitle="404 - Page not found" greenTitle="Oops!" />

      <section className="pt-7 pb-7 bg-black">
        {/* <div className="container nopage">
                <p className="">
                    The Page you are looking for doesn't exist or an other error occured.
                </p>
            </div> */}
      </section>
    </Layout>
  );
};

export default NoContent;
